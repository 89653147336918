import { toast } from 'vuetify-sonner';
import type { NotificationConfig } from '../models';
import { createNotificationConfig } from '../utils';
import { useI18nInstance } from '../../i18n';

class NotificationService {
  public show = (config: Partial<NotificationConfig> & Pick<NotificationConfig, 'text'>) => {
    const i18n = useI18nInstance();

    const generatedConfig = createNotificationConfig(config);
    const needTranslate = generatedConfig.needTranslate;
    const translatePlaceholders = generatedConfig.translatePlaceholders;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    const text = i18n && needTranslate ? i18n.global.t(generatedConfig.text, translatePlaceholders) : generatedConfig.text;

    toast(text, {
      description: generatedConfig.description,
      duration: generatedConfig.timeout,
      cardProps: {
        color: generatedConfig.color,
        width: 360
      },
      action: {
        label: 'x'
      }
    });
  };

  public showInfo = (text: string, config: Partial<Omit<NotificationConfig, 'text' | 'color'>> = {}) => {
    this.show({
      ...config,
      text,
      color: 'info'
    });
  };

  public showSuccess = (text: string, config: Partial<Omit<NotificationConfig, 'text' | 'color'>> = {}) => {
    this.show({
      ...config,
      text,
      color: 'success'
    });
  };

  public showWarning = (text: string, config: Partial<Omit<NotificationConfig, 'text' | 'color'>> = {}) => {
    this.show({
      ...config,
      text,
      color: 'warning'
    });
  };

  public showError = (text: string, config: Partial<Omit<NotificationConfig, 'text' | 'color'>> = {}) => {
    this.show({
      ...config,
      text,
      color: 'error'
    });
  };

  public promise = async <TResult>(promise: Promise<TResult>, config: Partial<NotificationConfig> & { errorText?: string } = {}) => {
    return promise
      .then((result) => {
        this.showSuccess(config.text || 'notification.default.success', config);
        return result;
      })
      .catch((error) => {
        const errorData = error.data.data || null;
        if (errorData && 'errors' in errorData && Array.isArray(errorData.errors)) {
          const errors = errorData.errors;
          errors.forEach((e: any) => this.showError(e.detail));
        } else {
          this.showError(config.errorText || 'notification.default.error');
        }

        return Promise.reject(error);
      });
  };

  public promiseCatch = async <TResult>(promise: Promise<TResult>, config: Partial<NotificationConfig> & { errorText?: string } = {}) => {
    return promise.catch((error) => {
      this.showError(config.text || 'notification.default.error', config);
      return Promise.reject(error);
    });
  };
}

const notificationService = new NotificationService();

export const useNotifications = () => notificationService;
