import { computed, type Ref } from 'vue';
import type { WelcomeModalConfig } from '../models/welcome-modal.model';
import { HideWelcomeModal } from '../enums/hide-wecome-modal.enum';
import { useLocalStorage } from '@vueuse/core';
import { useModals, WelcomeModal } from '../../modal';
import { useCommonApi } from '../../api';

export const useWelcomeModal = () => {
  const modals = useModals();
  const htmlContentApi = useCommonApi().htmlContent;

  const WELCOME_MODAL_KEY = 'application.greetingModal.content';
  const HIDE_WELCOME_MODAL = 'hideWelcomeModal';
  const LAST_WELCOME_MODAL_CONTENT = 'lastWelcomeModalContent';

  const hideWelcomeModal: Ref<string | null> = useLocalStorage(HIDE_WELCOME_MODAL, null);
  const lastWelcomeModalContent: Ref<string | null> = useLocalStorage(LAST_WELCOME_MODAL_CONTENT, null);

  const updateHideWelcomeModal = async () => {
    const htmlContent = await htmlContentApi.loadContent(WELCOME_MODAL_KEY, 'hu');
    if (htmlContent.data.html && lastWelcomeModalContent.value !== htmlContent.data.html) {
      lastWelcomeModalContent.value = htmlContent.data.html;
      hideWelcomeModal.value = HideWelcomeModal.SHOW;
    }
  };

  const getHideWelcomeModal = computed(() => hideWelcomeModal.value);

  const openWelcomeModal = () => {
    modals.open<WelcomeModalConfig>(WelcomeModal, {
      text: WELCOME_MODAL_KEY,
      persistent: true,
      translate: false,
      scrollable: true,
      onConfirm: async (checked) => {
        if (checked) {
          hideWelcomeModal.value = HideWelcomeModal.HIDE;
        } else {
          hideWelcomeModal.value = HideWelcomeModal.SHOW;
        }
      }
    });
  };

  return { openWelcomeModal, updateHideWelcomeModal, getHideWelcomeModal };
};
