export enum Permission {
  // customers
  CUSTOMER_LIST = 'admin.customer.list', // can see customer list (menu)
  CUSTOMER_LIST_ALL = 'admin.customer.list.all', // can see every customer
  CUSTOMER_LIST_OWN = 'admin.customer.list.own', // can see owned customers only
  CUSTOMER_EDIT = 'admin.customer.edit', // can edit a customer
  CUSTOMER_EDIT_RR = 'admin.customer.edit-rr', // can edit a customer's regional representative
  CUSTOMER_IMPERSONATE = 'admin.customer.impersonate', // can impersonate a user

  // offers
  OFFER_LIST = 'admin.offer.list', // can see offer list (menu)
  OFFER_DETAILS = 'admin.offer.details', // can see an offer's details page
  OFFER_CONTRACT = 'admin.offer.contract', // can open offer contract
  OFFER_CONTRACT_APPROVE = 'admin.offer.contract.approve', // can approve offer contract
  OFFER_DOWNLOAD = 'admin.offer.download', // can download offer pdf
  OFFER_ARCHIVE = 'admin.offer.archive', // can archive an offer
  OFFER_ACCEPT = 'admin.offer.accept', // can accept an offer
  OFFER_PRODUCT_PRICE_MODIFY = 'admin.product.price.modify', //can modify product price of new offer in calculator
  OFFER_SHAPE_GENERATE = 'admin.offer.shape.generate', // can generate and download offer shapefiles

  // orders
  ORDER_LIST = 'admin.order.list', // can see the orders list (menu)
  ORDER_LIST_ALL = 'admin.order.list.all', // can see every orders
  ORDER_LIST_OWN = 'admin.order.list.own', // can see owned customers' orders only

  // available orders
  AVAILABLE_ORDER_LIST = 'admin.available-order.list', // can see the available orders list (menu)

  // products
  PRODUCT_LIST = 'admin.product.list', // can see the products list (menu)

  // modules
  MODULE_MAP = 'maximap.modul.map',
  MODULE_PLAN = 'maximap.modul.plan',
  MODULE_MONITORING = 'maximap.modul.monitoring'
}
