import { toRaw, toValue } from 'vue';
import { Permission, RoleType } from '../enums';

export const createPermissionsFromRole = (role: RoleType | null) => {
  switch (role) {
    case RoleType.ADMIN:
    case RoleType.STAFF:
      return createAdminPermissions();
    case RoleType.REGIONAL_REPRESENTATIVE:
      return createRegionalRepresentativePermissions();
    case RoleType.SECRETARY:
      return createSecretaryPermissions();
    default:
      return [];
  }
};

export const checkPermission = (list: Permission[], included: Permission | Permission[]) => {
  const listToCheck = toRaw(toValue(list));
  const includedInCheck = toRaw(toValue(included));
  const includedList = Array.isArray(includedInCheck) ? includedInCheck : [includedInCheck];

  return !includedList.some((permission) => !listToCheck.includes(permission));
};

export const getAdminHomeRouteFromRole = (role: RoleType | null) => {
  switch (role) {
    case RoleType.SECRETARY:
      return { name: 'offers' };
    default:
      return { name: 'customers' };
  }
};

const createAdminPermissions = (): Permission[] => {
  return Object.values(Permission); // all permissions
};

const createRegionalRepresentativePermissions = (): Permission[] => {
  return [
    Permission.CUSTOMER_LIST,
    Permission.CUSTOMER_LIST_OWN,
    Permission.OFFER_LIST,
    Permission.OFFER_DETAILS,
    Permission.OFFER_CONTRACT,
    Permission.OFFER_DOWNLOAD,
    Permission.OFFER_ACCEPT,
    Permission.OFFER_ARCHIVE,
    Permission.ORDER_LIST,
    Permission.ORDER_LIST_OWN,
    Permission.AVAILABLE_ORDER_LIST,
    Permission.PRODUCT_LIST
  ];
};

const createSecretaryPermissions = (): Permission[] => {
  return [
    Permission.CUSTOMER_LIST,
    Permission.CUSTOMER_LIST_OWN,
    Permission.OFFER_LIST,
    Permission.OFFER_DETAILS,
    Permission.OFFER_CONTRACT,
    Permission.OFFER_CONTRACT_APPROVE,
    Permission.OFFER_DOWNLOAD,
    Permission.OFFER_SHAPE_GENERATE,
    Permission.ORDER_LIST,
    Permission.ORDER_LIST_OWN,
    Permission.AVAILABLE_ORDER_LIST,
    Permission.PRODUCT_LIST
  ];
};
