export default {
  dataTable: {
    viewMode: {
      table: 'Táblázat nézet',
      grid: 'Rács nézet'
    },
    sort: {
      sort: 'Rendezés',
      none: 'Alapértelmezett'
    },
    selectAll: 'Összes elem kijelölése'
  },
  dataManager: {
    paginator: {
      itemsPerPage: 'Elem / oldal'
    }
  },
  modal: {
    close: 'Bezárás',
    cancel: 'Mégse',
    confirm: 'Mehet',
    ok: 'Ok'
  },
  welcomeModal: {
    dontShowAgain: 'Ne jelenjen meg többször ez az üzenet'
  },
  validation: {
    required: 'Mező kitöltése kötelező!',
    companyNumber: 'Mező formátuma nem megfelelő! (BS-CF-NNNNNN)',
    minChars: 'Minimum {min} karakter!',
    maxChars: 'Maximum {max} karakter!',
    email: 'Nem megfelelő e-mail cím formátum!',
    parcel: 'Nem megfelelő helyrajzi szám formátum!'
  },
  form: {
    input: {
      datePicker: {
        selectDate: 'Válasszon dátumot'
      },
      formArray: {
        confirmClearTitle: 'Összes elem törlése',
        confirmClearText: 'Biztos, hogy törlöd az összes elemet?',
        confirmRemoveTitle: 'Elem törlése',
        confirmRemoveText: 'Biztos, hogy törlöd az elemet?'
      }
    },
    validator: {
      requiredTrue: 'A beleegyezés elfogadása kötelező!',
      required: {
        invalidMessage: 'A mező kitöltése kötelező'
      },
      length: {
        invalidMessage: 'A karakterek száma minimum {min} és maximum {max} lehet',
        invalidMessageMin: 'A karakterek száma minimum {min} lehet',
        invalidMessageMax: 'A karakterek száma maximum {max} lehet'
      },
      childrenValid: {
        invalidMessage: 'Kérjük ellenőrizze a blokkban megadott adatok helyességét'
      },
      email: {
        invalidMessage: 'Nem megfelelő e-mail cím formátum!'
      },
      companyNumber: {
        invalidMessage: 'Mező formátuma nem megfelelő! (BS-CF-NNNNNN)'
      },
      taxNumber: {
        invalidMessage: 'Mező formátuma nem megfelelő! (xxxxxxxx-y-zz)'
      },
      dateIsAfter: {
        invalidMessage: 'A dátum későbbi kell hogy legyen mint {dateToCompare}'
      },
      min: {
        invalidMessage: 'Minimum megadható érték: {min}'
      },
      max: {
        invalidMessage: 'Maximum megadható érték: {max}'
      }
    }
  },
  login: {
    signIn: 'Bejelentkezés',
    email: 'E-mail',
    password: 'Jelszó',
    rememberMe: 'Maradjak bejelentkezve',
    forgotPassword: 'Elfelejtett jelszó',
    backToLogin: 'Vissza a bejelentkezéshez',
    sendEmail: 'Email elküldése'
  },
  auth: {
    newPassword: 'Új jelszó',
    passwordConfirm: 'Jelszó megerősítése',
    setPassword: 'Jelszó beállítása',
    name: 'Név',
    email: 'Email',
    phoneNumber: 'Telefonszám',
    personalData: 'Személyes adatok',
    changePassword: 'Jelszó megváltoztatása',
    currentPassword: 'Jelenlegi jelszó',
    impersonatedLogin: 'Bejelentkezve a felhasználó nevében',
    impersonateUser: 'Bejelentkezés a felhasználó nevében',
    settings: 'Beállítások',
    demoOfferVisible: 'Minta adatok megjelenítése',
    validation: {
      passwordMustMatch: 'A jelszavaknak egyeznie kell!',
      passwordMinLength: 'A jelszónak minimum {min} karakter hosszúnak kell lennie!',
      passwordFormat: 'A jelszónak tartalmaznia kell kisbetűt, nagybetűt, számot és speciális karaktert!'
    },
    hint: {
      passwordFormat:
        'A jelszónak minimum {min} karakter hosszúnak kell lennie, tartalmaznia kell kisbetűt, nagybetűt, számot és speciális karaktert!'
    },
    btn: {
      save: 'Mentés'
    },
    notification: {
      savePersonalData: {
        success: 'Sikeres módosítás!',
        error: 'Hiba történt a módosítás során!'
      },
      saveSettings: {
        success: 'Sikeres mentés!',
        error: 'Hiba történt a mentés során!'
      },
      saveCustomerDetails: {
        success: 'Sikeres módosítás!',
        error: 'Hiba történt a módosítás során!'
      },
      createCustomer: {
        success: 'Sikeres létrehozás!',
        error: 'Hiba történt a létrehozás során!'
      },
      changePassword: {
        success: 'Sikeres jelszó módosítás!',
        error: 'Hiba történt a jelszó módosítása során!'
      },
      api: {
        noActiveAccount: 'Sikertelen bejelentkezés! Az email cím vagy a jelszó nem megfelelő!'
      },
      login: {
        missingPermissions: 'Nem rendelkezik a belépéshez szükséges jogosultságokkal!'
      }
    }
  },
  notification: {
    default: {
      success: 'Sikeres művelet',
      error: 'Hiba történt a művelet végrehajtása során'
    }
  },

  // TODO: remove after backend finishes translation module:
  unitName: {
    hectare: 'Terület',
    numberOfVehicles: 'Gépek száma'
  },

  forgotPassword: {
    notification: {
      text: 'Email sikeresen elküldve!',
      errorText: 'Ezzel az email címmel nincs aktív felhasználó!'
    }
  },
  map: {
    calculationMode: {
      isolated: 'Izolált',
      merged: 'Összesített'
    }
  },
  htmlContent: {
    loadingError: 'Hiba történt az szöveges tartalom betöltése során'
  }
};
